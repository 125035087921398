@use "_elements/_icons2" as elements;
@use "_icons" as icon;
@use "_property" as property;
@use "colors-lib" as *;
@import "leaflet/dist/leaflet.css";

static-map {
    .static-map-wrapper {
        .estate-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: white;
            cursor: grab;
            background-size: 32px 32px;
            background-position: center center;
            background-repeat: no-repeat;
            background-image: property.property-naering($blue-15);
            @include elements.get-property-icon($blue-15);
        }
    }
}
