@use "_icons" as icon;
@use "_colors-lib" as *;
@use "static-map.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");

@font-face {
    font-family: 'FinnfontLight';
    src: url('../finnfont/finn-light.woff2');
}

@font-face {
    font-family: 'FinnfontMedium';
    src: url('../finnfont/finn-medium.woff2');
}

html, body { height: 100%; }

html {
    font-family: 'Open Sans', sans-serif;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

body {
    color: $grey-20;
    background-color: white;
    cursor: default;
    margin: 0;
}

h1 {
    font-size: 21px;
    font-weight: 400;
    letter-spacing: -0.4px;
}

h2,h3,h4,h5 ,h6{
    font-size: 18px;
    font-weight: 400;
    color: $black;
}

a {
    color: $blue-20;
    text-decoration: none;
}

th {
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    color: $grey-20;
}

td {
    font-size: 14px;
    font-weight: 400;
    color: $grey-20;
}

button { //RESET bROWSER STYLES
    background-color: transparent;
    border-width: 0;
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding: 0;
  }


button {
    height: 32px;
    padding-right: 20px;
    padding-left: 20px;
    color: white;
    background-color: $blue-25;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        background-color: $blue-70;
    }

    &.light {
        color: $blue-20;
        background-color: $blue-05;
    }

    &.search {
        min-width: 50px;
        background-color: $green-35;
        color: white;
        cursor: pointer;
        background-image: icon.icon-search($white);
        background-size: 24px;
        background-position: center;
        background-repeat: no-repeat;
    }

    &:disabled {
        background-color: $grey-15;
        color: $grey-05;
        cursor: default;
    }
}

input {
    color: black;
}

