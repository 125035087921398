﻿@use "../energimerke" as energimerke;
@use "../property" as property;
@use "sass:string";
@use "sass:meta";
@use "sass:map";

$data-svg-prefix: 'data:image/svg+xml;utf-8,';

@function get-icon($icon, $color: #fff) {
  @if 'color' != type-of($color) {
    // @warn 'The requested color - "' + $color + '" - was not recognized as a Sass color value.';
    @return null;
  }

  @if map-has-key($icons, $icon) {
    $icon: map-get($icons, $icon);
    $placeholder: '%%COLOR%%';
    /*$data-uri: str-replace( url($data-svg-prefix + $icon ), $placeholder, $color);*/
    $data-uri: str-replace($data-svg-prefix + $icon, $placeholder, $color);
    $data-uri: str-replace($data-uri, '#', '%23');
    @return 'url(' + $data-uri + ')';
  }

  // @warn 'The requested icon - "' + $icon + '" - is not defined in the $icons map.';
  @return null;
}

@mixin get-energy-icons() {
  $energy-ratings: (
    'red': #c9252c,
    'orange': #f47920,
    'yellow': #eacc1b,
    'lightgreen': #80c342,
    'green': #009247,
  );
  $energy-labels: a, b, c, d, e, f, g;
  $keys: map-keys($energy-ratings);
  @each $energy-rating in $keys {
    @each $energy-label in $energy-labels {
      $icon: 'icon-energy-' + $energy-label;
      &.#{$energy-label}-#{$energy-rating} {
        background-image: meta.call(get-function($icon, false, 'energimerke'), map-get($energy-ratings, $energy-rating));
      }
    }
  }
}

@mixin get-property-icon($color) {
  $properties: 'leilighet', enebolig, tomt, landbruk, borettslag, boligtomt, tomannsbolig, rekkehus, fritidsbolig, kulturidrett, annet,
    garasje, ukjent, naering, ubebygd, media, offentlig, religion, skole, transport, nybygg, tomanns, helse;

  @each $property in $properties {
    $first: string.slice($property, 1, 1);
    $last: string.slice($property, 2);

    $upper: string.to-upper-case($first) + $last;

    $className: '';
    @if $property == naering {
      $className: '&.Næring, &.næring, &.#{$upper}, &.#{$property}';
    } 
    @else if $property == kulturidrett {
      $className: '&.KulturIdrett, &.kulturIdrett';
    } 
    @else {
      $className: '&.#{$upper}, &.#{$property}';
    }

    

    #{$className} {
      $icon: '';

      @if $property == ubebygd or $property == boligtomt {
        $icon: 'property-tomt';
      } @else if $property == tomanns {
        $icon: 'property-tomannsbolig';
      }
      @else {
        $icon: 'property-' + $property;
      }
      background-image: meta.call(get-function($icon, false, 'property'), $color);
    }
  }
}

@mixin get-owner-icons($colors...) {
  $ownertypes: 'Person', 'Organisasjon', 'Business', 'Eiendom', 'Property';

  @each $ownertype in $ownertypes {
    $owner: '';

    @if ($ownertype == Organisasjon) {
      $owner: 'business';
    } @else if($ownertype == Eiendom) {
      $owner: 'property';
    } @else {
      $owner: string.to-lower-case($ownertype);
    }

    &.#{$ownertype} {
      $icon: 'owner-' + $owner;
      background-image: meta.call(get-function($icon, false, 'property'), $colors...);
    }
  }
}

@mixin get-no-images() {
  $estate-types: Leilighet, Tomt, Annet, Ubebygd, Landbruk, Offentlig, Religion, Transport, Garasje, Naering, Media, Kultur, Skole, Enebolig,
    Fritidsbolig, Rekkehus, Tomannsbolig;

  @each $estate in $estate-types {

    $className: '';
    @if $estate == Naering {
      $className: '&.Næring, &.#{$estate}'
    } @else {
      $className: '&.#{$estate}'
    }
    #{$className} {
      $icon: 'emptycarousel-'+string.to-lower-case($estate);
      background-size: 710px auto;
      background-image: meta.call(get-function($icon, false, 'property'));
    }
  }
}
